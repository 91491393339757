import React, { useState } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Breadcrumb,
    InputGroup,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import Switch from "react-switch";
import Fancybox from "../../Component/FancyBox";
import { toast } from "react-toastify";
import { StampCategoryDropdown, StampEdit, StampView } from "../../Auth/Api";
import { useEffect } from "react";
import { SelectPicker } from "rsuite";
import moment from "moment"

const data = [
    "Eugenia",
    "Bryan",
    "Linda",
    "Nancy",
    "Lloyd",
    "Alice",
    "Julia",
    "Albert",
].map((item) => ({ label: item, value: item }));

const StampsEdit = () => {
    const Redirect = useNavigate();
    const { id } = useParams();
    const [loading, Setloading] = useState(false);
    const [validate, setValidate] = useState(false);
    const [categoryDropdownArray, setCategoryDropdownArray] = useState([]);
    const [selectedCategoryError, setSelectedCategoryError] = useState(false); // New state for validation

    const [Data, SetData] = useState({
        main_image: "",
        zip_name: "",
        zip_name_ios: "",
        is_premium: 0,
        is_premium_ios: 0,
        status: 0,
        status_ios: 0,
        stamp_image: "",
        stamp_background_image: "",
        category_id: "",
        // is_new: "",
        tansparent_image: "",
        date: "",
        time: "",
        title:"",
        content: "",
        notification_image: ""
    });
    const [isNotification, setIsNotification] = useState(0);
    const InputFile = (e) => {

        SetData({ ...Data, [e.target.name]: e.target.files[0] });

        setValidate(false);
    };

    const GetData = async () => {
        const Result = await StampView(id);
        if (Result.data.Status === true) {
            SetData({
                main_image: Result.data.Data.thumb_image,
                zip_name: Result.data.Data.zip_name,
                zip_name_ios: Result.data.Data.zip_name_ios,
                is_premium: Result.data.Data.is_premium,
                is_premium_ios: Result.data.Data.is_premium_ios,
                stamp_image: Result.data.Data.stamp_image,
                stamp_background_image: Result.data.Data.stamp_background_image,
                tansparent_image: Result.data.Data.tansparent_image,
                status: Result.data.Data.status,
                status_ios: Result.data.Data.status_ios,
                category_id: Result?.data?.Data?.category_id?._id || "",
                date: Result?.data?.Data?.notification?.date || "",
                time: Result?.data?.Data?.notification?.time || "",
                title: Result?.data?.Data?.notification?.title || "",
                content: Result?.data?.Data?.notification?.content || "",
                notification_image: Result?.data?.Data?.notification?.notification_image || "",
                // is_new: Result?.data?.Data?.is_new,
            });
            setIsNotification(Result?.data?.Data?.notification? 1 : 0)
        }
    };
    const categoryDropdown = async () => {
        const Result = await StampCategoryDropdown();
        setCategoryDropdownArray(
            Result?.data?.Data.map((val, index) => {
                return { label: val.name, value: val._id };
            })
        );
    };
    useEffect(() => {
        categoryDropdown();
    }, []);
    const handleOnchageSelect = (e) => {
        SetData({ ...Data, category_id: e });
        setValidate(false);
        setSelectedCategoryError(false); // R
    };


    // console.log("Data -->", Data);
    const Save = async (event) => {
        event.preventDefault()
        // console.log('event -->', event)
        const form = event.currentTarget;
        if (form.checkValidity() === false || Data.category_id == "") {
            // console.log(form.checkValidity())
            // event.preventDefault();
            event.stopPropagation();
            setValidate(true);
        } else {

            const Result = await StampEdit(Data, id);
            if (Result.data.Status === true) {
                toast.success("Data Saved Successfully");
                Setloading(false);
                Redirect(`/Stamps/view/${id}`);
            } else {
                toast.error(Result.data.Response_Message);
                Setloading(false);
            }
        }
        Setloading(false);

        // if (form.checkValidity() === true && Data.category_id !== "") {
        //     // Setloading(true);
        //     e.stopPropagation();
        //     setValidate(true);
        //     const Result = await StampEdit(Data, id);
        //     if (Result.data.Status === true) {
        //         toast.success("Data Saved Successfully");
        //         Setloading(false);
        //         Redirect(`/Stamps/view/${id}`);
        //     } else {
        //         toast.error(Result.data.Response_Message);
        //         Setloading(false);
        //     }
        // } 
    };

    useEffect(() => {
        GetData();
    }, []);
    // console.log("Data --->", Data);
    // {console.log('isNotification', isNotification)}
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Stamps Edit</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item>
                        <Link to="/Home">
                            <i className="bx bx-home-alt me-2 fs-5"></i> Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/Stamps">Stamps</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Stamps Edit</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validate} onSubmit={Save}>
                    <Card className="mb-4">
                        <Card.Body>
                            {loading === true ? <div className="loader"></div> : <></>}
                            <Row >
                                <Col md={6}>
                                    <Form.Label>Category</Form.Label>
                                    <SelectPicker
                                        data={categoryDropdownArray}
                                        cleanable={false}
                                        defaultValue={Data.category_id}
                                        onChange={(e) => handleOnchageSelect(e)}
                                        value={Data.category_id}
                                        className={`my-2 ${selectedCategoryError ? "is-invalid" : ""
                                            }`} // Apply is-invalid class if there is an error
                                        block
                                        placeholder="Select Category"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Category Field Is Required
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Thumb Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="main_image"
                                            onChange={(e) => {
                                                InputFile(e);
                                            }}
                                            {...((!Data.main_image) && { required: true })}

                                        />
                                        <Fancybox>
                                            <a href={Data.main_image} data-fancybox="gallery">
                                                <img
                                                    src={Data.main_image}
                                                    alt=""
                                                    className="hv-40 rounded-3"
                                                />
                                            </a>
                                        </Fancybox>
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="stamp_image"
                                            onChange={(e) => {
                                                InputFile(e);
                                            }}
                                            {...((!Data.stamp_image) && { required: true })}

                                        />
                                        <Fancybox>
                                            <a href={Data.stamp_image} data-fancybox="gallery">
                                                <img
                                                    src={Data.stamp_image}
                                                    alt=""
                                                    className="hv-40 rounded-3"
                                                />
                                            </a>
                                        </Fancybox>
                                        <Form.Control.Feedback type="invalid">
                                            Image Field Is Require
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Background Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="stamp_background_image"
                                            onChange={(e) => {
                                                InputFile(e);
                                            }}
                                            {...((!Data.stamp_background_image) && { required: true })}

                                        />
                                        <Fancybox>
                                            <a
                                                href={Data.stamp_background_image}
                                                data-fancybox="gallery"
                                            >
                                                <img
                                                    src={Data.stamp_background_image}
                                                    alt=""
                                                    className="hv-40 rounded-3"
                                                />
                                            </a>
                                        </Fancybox>
                                        <Form.Control.Feedback type="invalid">
                                            Bacekground Image Field Is Require
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Transparent Image</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            name="tansparent_image"
                                            onChange={(e) => {
                                                InputFile(e);
                                            }}
                                            {...((!Data.tansparent_image) && { required: true })}

                                        />
                                        <Fancybox>
                                            <a href={Data.tansparent_image} data-fancybox="gallery">
                                                <img
                                                    src={Data.tansparent_image}
                                                    alt=""
                                                    className="hv-40 rounded-3"
                                                />
                                            </a>
                                        </Fancybox>
                                        <Form.Control.Feedback type="invalid">
                                            Stam Tpransparent Image Field Is Require
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>ZIP Name</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            accept=".zip"
                                            name="zip_name"
                                            onChange={(e) => {
                                                InputFile(e);
                                            }}
                                            {...((!Data.zip_name) && { required: true })}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>ZIP Name iOS</Form.Label>
                                    <InputGroup className="my-2">
                                        <Form.Control
                                            type="file"
                                            accept=".zip"
                                            name="zip_name_ios"
                                            onChange={(e) => {
                                                InputFile(e);
                                            }}
                                            {...((!Data.zip_name_ios) && { required: true })}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">Is Premium</Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            SetData({ ...Data, is_premium: e === true ? 1 : 0 });
                                        }}
                                        checked={Data.is_premium === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={<div className="react-switch-off">NO</div>}
                                        checkedIcon={<div className="react-switch-on">YES</div>}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">
                                        Is Premium iOS
                                    </Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            SetData({ ...Data, is_premium_ios: e === true ? 1 : 0 });
                                        }}
                                        checked={Data.is_premium_ios === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={<div className="react-switch-off">NO</div>}
                                        checkedIcon={<div className="react-switch-on">YES</div>}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">Status</Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            SetData({ ...Data, status: e === true ? 1 : 0 });
                                        }}
                                        checked={Data.status === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={<div className="react-switch-off">OFF</div>}
                                        checkedIcon={<div className="react-switch-on">ON</div>}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">Status iOS</Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            SetData({ ...Data, status_ios: e === true ? 1 : 0 });
                                        }}
                                        checked={Data.status_ios === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={<div className="react-switch-off">OFF</div>}
                                        checkedIcon={<div className="react-switch-on">ON</div>}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">Is Notification</Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            setIsNotification((e === true) ? 1 : 0);
                                            if (!e) {
                                                SetData(prev => ({
                                                    ...prev,
                                                    date: "",
                                                    time: "",
                                                    content: "",
                                                    title: "",
                                                    notification_image: ""
                                                }));
                                            }
                                        }}
                                        checked={isNotification === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={
                                            <div className="react-switch-off">No</div>
                                        }
                                        checkedIcon={
                                            <div className="react-switch-on">Yes</div>
                                        }
                                    />
                                </Col>
                                {isNotification ? <Row className="mt-2">
                                    <Col md={4}>
                                        <Form.Label className="d-block mb-2">Date</Form.Label>
                                        <input
                                            type="date"
                                            className="form-control col-4 my-2"
                                            min={new Date().toISOString().split('T')[0]}
                                            onChange={(e) => SetData((prev) => ({ ...prev, date: e.target.value }))}
                                            value={Data.date}
                                            required={isNotification ? true : false || Data.date < new Date(Data.date)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Invalid Date
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label className="d-block mb-2">Time</Form.Label>
                                        <input
                                            type="time"
                                            min={moment(Data.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(Data.time, 'HH:mm').format('HH:mm') < moment().format('HH:mm') ? moment().format('HH:mm') : ""}
                                            // min={"12:45"}
                                            onChange={(e) => SetData((prev) => ({ ...prev, time: e.target.value }))}
                                            value={Data.time}
                                            className="form-control col-4 my-2"
                                            // required={isNotification ? true : false}
                                             />
                                        {/* <Form.Control.Feedback type="invalid">
                                            Invalid Time
                                        </Form.Control.Feedback> */}
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label>Notification Image</Form.Label>
                                        <InputGroup className="my-2">
                                            <Form.Control
                                                type="file"
                                                name="notification_image"
                                                onChange={(e) => {
                                                    console.log('e', e)
                                                    InputFile(e);
                                                }}
                                                className="form-control col-4"
                                                // {...((!Data.notification_image) && { required: isNotification ? true : false })}
                                            />
                                            <Fancybox>
                                                {/* {console.log('Data.notification_image', Data)} */}
                                                <a href={Data.notification_image} data-fancybox="gallery">
                                                    <img
                                                        src={Data.notification_image}
                                                        alt=""
                                                        className="hv-40 rounded-3"
                                                    />
                                                </a>
                                            </Fancybox>
                                            {/* <Form.Control.Feedback type="invalid">
                                                Notification Image Field Is Require
                                            </Form.Control.Feedback> */}
                                        </InputGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label className="d-block mb-2">title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            rows={3}
                                            className="my-2"
                                            maxLength={33}
                                            value={Data.title}
                                            onChange={(e) => SetData((prev) => ({ ...prev, title: e.target.value }))}
                                            required={isNotification ? true : false} />
                                        <Form.Control.Feedback type="invalid">
                                            title Is Require
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label className="d-block mb-2">Content</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={2}
                                            maxLength={133}
                                            className="my-2"
                                            value={Data?.content}
                                            onChange={(e) => SetData((prev) => ({ ...prev, content: e.target.value }))}
                                            required={isNotification ? true : false} />
                                        <Form.Control.Feedback type="invalid">
                                            Content Is Require
                                        </Form.Control.Feedback>
                                    </Col>

                                </Row> : <></>}
                                {/* <Col md={3}>
                                    <Form.Label className="d-block mb-2">Is New</Form.Label>
                                    <Switch
                                        onChange={(e) => { SetData({ ...Data, is_new: (e === true) ? 1 : 0 }) }}
                                        checked={(Data?.is_new === 1) ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={
                                            <div className="react-switch-off">No</div>
                                        }
                                        checkedIcon={
                                            <div className="react-switch-on">Yes</div>
                                        }
                                    />
                                </Col> */}
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" className="me-3" type="submit">
                                Save
                            </Button>
                            <Link to={`/Stamps/view/${id}`} className="btn btn-secondary">
                                Cancel
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    );
};

export default StampsEdit;
